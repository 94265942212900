// extracted by mini-css-extract-plugin
export var afterCaption = "maine_digest_2021_11-module--afterCaption--f32d0";
export var columnWrapper = "maine_digest_2021_11-module--columnWrapper--828ab";
export var dateline = "maine_digest_2021_11-module--dateline--6f77c";
export var heading = "maine_digest_2021_11-module--heading--632ec";
export var headingLogo = "maine_digest_2021_11-module--headingLogo--d0e36";
export var headingWrapper = "maine_digest_2021_11-module--headingWrapper--4a1ce";
export var heroImage = "maine_digest_2021_11-module--heroImage--a8c73";
export var instance = "maine_digest_2021_11-module--instance--22e12";
export var latestnewsarticleheadline = "maine_digest_2021_11-module--latestnewsarticleheadline--b71ac";
export var maineDigest = "maine_digest_2021_11-module--maineDigest--a700f";
export var photo = "maine_digest_2021_11-module--photo--5b8c9";
export var photoCaption = "maine_digest_2021_11-module--photoCaption--af042";
export var photosWrapper = "maine_digest_2021_11-module--photosWrapper--e43f7";
export var rightCaptions = "maine_digest_2021_11-module--rightCaptions--80ed1";
export var rightPhoto = "maine_digest_2021_11-module--rightPhoto--e1ec9";
export var rightPhotoCaption = "maine_digest_2021_11-module--rightPhotoCaption--127ac";
export var subheading = "maine_digest_2021_11-module--subheading--0ce27";
export var textWrapper = "maine_digest_2021_11-module--textWrapper--0b825";
export var whatsNew = "maine_digest_2021_11-module--whatsNew--a8623";